// src/QRCodeGenerator.js
import React, { useState, useRef, useCallback } from 'react';
import { QRCodeSVG } from 'qrcode.react'; // Using QRCodeSVG
import GridComponent from './GridComponent';
import './styles/ui-styling-redesign.css';

// Define your default grid labels
const defaultGridLabels = [
  'FD001ORA60SDG / 66 pc', 'FD002ORA60SDG / 42 pc', 'FD003ORA60SDG / 132 pc', 'FD004ORA60SDG / 54 pc',
  'FD005ORA60SDG / 72 pc', 'FD006ORA60SDG / 36 pc', 'FD007ORA60SDG / 36 pc', 'FD008ORA60SDG / 30 pc',
  'FD009ORA60SDG / 40 pc', 'FD010ORA60SDG / 54 pc', 'FD011ORA60SDG / 45 pc', 'FD012ORA60SDG / 99 pc',
  'Item 13', 'Item 14', 'Item 15', 'Item 16', 'Item 17', 'Item 18',
  'NW001ORA55SDB / 168 pc', 'NW002ORA55SDB / 20 pc', 'NW003ORA55SDB / 8 pc', 'NW004ORA55SDB / 4 pc',
  'NW005ORA55SDB / 25 pc', 'NW006ORA55SDB / 48 pc', 'NW007ORA55SDB', 'NW008ORA55SDB / 36 pc',
  'NW009ORA55SDB / 20 pc', 'NW010ORA55SDB / 20 pc', 'NW011ORA55SDB / 5 pc', 'NW012ORA55SDB / 5 pc',
  'Item 31', 'Item 32', 'Item 33', 'Item 34', 'Item 35', 'Item 36',
];

function QRCodeGenerator() {
  const [values, setValues] = useState([...defaultGridLabels]);
  const [selectedField, setSelectedField] = useState(null);
  const [qrContent, setQrContent] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const fileInputRef = useRef(null);

  // Handle input changes
  const handleInputChange = useCallback(
    (index, newValue) => {
      if (isEditing) {
        setValues((prevValues) => {
          const newValues = [...prevValues];
          newValues[index] = newValue;
          return newValues;
        });
        if (selectedField && selectedField.index === index) {
          setSelectedField({
            index,
            label: defaultGridLabels[index],
            value: newValue || defaultGridLabels[index],
          });
        }
      }
    },
    [isEditing, selectedField],
  );

  // Handle field selection
  const handleSelection = (index) => {
    let value = values[index].trim();
    if (!value) {
      value = defaultGridLabels[index];
    }
    setSelectedField({
      index,
      label: defaultGridLabels[index],
      value,
    });
  };

  // Format date
  const formatDate = () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear();
    return `${day}.${month}.${year}`;
  };

  // Generate QR code content
  const generateQRCode = () => {
    if (selectedField && selectedField.value) {
      const date = formatDate();
      const [firstPart, secondPart] = selectedField.value
        .split('/')
        .map((part) => part.trim());
      const secondValue = secondPart || '0';
      const content = `${firstPart} / ${secondValue} / Date: ${date}`;
      setQrContent(content);
    } else {
      alert('Please select a field with a value.');
    }
  };

  // Toggle edit mode
  const toggleEdit = () => {
    setIsEditing((prev) => !prev);
  };

  // Save grid content
  const saveGridContent = () => {
    const content = JSON.stringify(values);
    const blob = new Blob([content], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'grid_content.json';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
    alert('Grid content saved successfully!');
  };

  // Load grid content
  const loadGridContent = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type !== 'application/json') {
        alert('Please select a valid JSON file.');
        return;
      }
      const reader = new FileReader();
      reader.onload = (e) => {
        try {
          const loadedValues = JSON.parse(e.target.result);
          if (Array.isArray(loadedValues) && loadedValues.length === 36) {
            setValues(loadedValues);
            setSelectedField(null);
            alert('Grid content loaded successfully!');
          } else {
            alert('Invalid file format: The file must contain an array of 36 items.');
          }
        } catch (error) {
          alert('Error reading file: Invalid JSON.');
        }
      };
      reader.readAsText(file);
    }
  };

  // Reset grid to default values
  const resetToDefault = () => {
    if (window.confirm('Are you sure you want to reset to default values?')) {
      setValues([...defaultGridLabels]);
      setSelectedField(null);
    }
  };

  // Print QR code by embedding SVG directly
  const printQRCode = () => {
    if (!qrContent) {
      alert('Please generate a QR code first.');
      return;
    }

    const svgElement = document.getElementById('qr-code-svg');
    if (!svgElement) {
      alert('QR code is not available.');
      return;
    }

    // Get the SVG code as a string
    const svgString = svgElement.outerHTML;

    // Split the qrContent by '/' and create line breaks
    const formattedTextSegments = qrContent
      .split('/')
      .map((segment) => segment.trim());

    // Determine the title based on the first two characters
    let title = '';
    const firstTwoChars = formattedTextSegments[0].substring(0, 2).toUpperCase();
    if (firstTwoChars === 'FD') {
      title = 'Feeding Wheels';
    } else if (firstTwoChars === 'NW') {
      title = 'No Crush Wheels';
    }

    // Combine the title and formatted text content
    const formattedTextContent = `
      ${title ? `<strong>${title}</strong><br/>` : ''}
      ${formattedTextSegments.join('<br/>')}
    `;

    const printableContent = `
      <html>
        <head>
          <title>Print QR Code Label</title>
          <style>
            @page {
              size: 76mm 38mm;
              margin: 2mm;
            }
            body {
              margin: 0;
              padding: 0;
            }
            .label-container {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              box-sizing: border-box;
            }
            .text-content {
              width: 48%;
              font-size: 10pt;
              word-wrap: break-word;
              padding-right: 5px;
            }
            .qr-code {
              width: 48%;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            /* Adjust the QR code size */
            .qr-code svg {
              width: 90%;
              height: 90%;
            }
            /* Remove margins and paddings */
            html, body, .label-container {
              margin: 0;
              padding: 0;
            }
          </style>
        </head>
        <body>
          <div class="label-container">
            <div class="text-content">
              ${formattedTextContent}
            </div>
            <div class="qr-code">
              ${svgString}
            </div>
          </div>
        </body>
      </html>
    `;

    const printWindow = window.open('', '', 'width=800,height=600');
    printWindow.document.write(printableContent);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  return (
    <div className="app-container">
      {/* Header */}
      <header>
        QR Code Generator
      </header>

      {/* Buttons */}
      <div className="button-group">
        {/* Edit Button */}
        <button
          onClick={toggleEdit}
          className={`edit-button ${isEditing ? 'editing' : ''}`}
          aria-label="Toggle Edit Mode"
        >
          {isEditing ? 'EDITING' : 'EDIT'}
        </button>

        {/* Save Button */}
        <button
          type="button"
          onClick={saveGridContent}
          className="save-button"
          aria-label="Save Grid"
        >
          <span>SAVE</span>
        </button>

        {/* Load Button */}
        <button
          type="button"
          onClick={() => fileInputRef.current.click()}
          className="load-button"
          aria-label="Load Grid"
        >
          <span>LOAD</span>
        </button>

        {/* Reset Button */}
        <button
          type="button"
          onClick={resetToDefault}
          className="reset-button"
          aria-label="Reset Grid"
        >
          <span>RESET</span>
        </button>

        {/* Hidden File Input */}
        <input
          type="file"
          ref={fileInputRef}
          className="file-input"
          onChange={loadGridContent}
          accept=".json"
        />
      </div>

      {/* Form */}
      <form onSubmit={(e) => e.preventDefault()}>
        {/* Grid Component */}
        <GridComponent
          labels={defaultGridLabels}
          values={values}
          handleInputChange={handleInputChange}
          handleSelection={handleSelection}
          isEditing={isEditing}
          selectedField={selectedField}
        />

        {/* Selected Field Display */}
        <input
          type="text"
          value={
            isEditing
              ? `Date: ${formatDate()}`
              : selectedField && selectedField.value
              ? (() => {
                  const [firstPart, secondPart] = selectedField.value
                    .split('/')
                    .map((part) => part.trim());
                  const secondValue = secondPart || '0';
                  return `${firstPart} / ${secondValue} / Date: ${formatDate()}`;
                })()
              : `Date: ${formatDate()}`
          }
          readOnly
          className="selected-field-display"
          aria-label="QR Code Content"
        />

        {/* Generate QR Code Button */}
        <button
          type="button"
          onClick={generateQRCode}
          className="generate-button"
          aria-label="Generate QR Code"
          disabled={isEditing}
        >
          <span>Generate QR Code</span>
        </button>
      </form>

      {/* QR Code Display */}
      {qrContent && (
        <div className="qr-code-section">
          <h3>Your Generated QR Code:</h3>
          <div id="printable" className="qr-code-container">
            <QRCodeSVG id="qr-code-svg" value={qrContent} size={230} /> {/* Reduced size by 10% */}
          </div>
          {/* Print QR Code Button */}
          <button
            onClick={printQRCode}
            className="print-button"
            aria-label="Print QR Code"
          >
            <span>Print QR Code</span>
          </button>
        </div>
      )}
    </div>
  );
}

export default QRCodeGenerator;
