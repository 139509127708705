// src/GridComponent.js
import React from 'react';
import './styles/ui-styling-redesign.css';

const GridComponent = ({
  labels,
  values,
  handleInputChange,
  handleSelection,
  isEditing,
  selectedField,
}) => {
  return (
    <div className="grid">
      {labels.map((label, index) => (
        <textarea
          key={index}
          value={values[index]}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onClick={() => handleSelection(index)}
          placeholder={label}
          rows={2}
          className={`grid-item ${
            selectedField && selectedField.index === index ? 'selected' : ''
          }`}
          readOnly={!isEditing}
          aria-label={`Field ${index + 1}`}
        />
      ))}
    </div>
  );
};

export default GridComponent;
