// src/App.js
import React from 'react';
import QRCodeGenerator from './QRCodeGenerator';
import './styles/ui-styling-redesign.css'; // Import the CSS file here if it's global

function App() {
  return (
    <div>
      <QRCodeGenerator />
    </div>
  );
}

export default App;
